/*------------------------------------*\
    $FONT-FACE
\*------------------------------------*/

@font-face {
    font-family: "NeuzeitSLTStd-Book";
    src: url(../webfonts/NeuzeitSLTStd-Book.woff) format("woff"), url(../webfonts/NeuzeitSLTStd-Book.woff2) format("woff2");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'lg';
    src: url(../webfonts/lg.woff?n1z373) format("woff");
    font-weight: normal;
    font-style: normal;
}

