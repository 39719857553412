/*------------------------------------*\
    $WORK
\*------------------------------------*/

.work {
    @include media-query(wide) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: rem(40px);
    }
}

.work-content {
    margin: rem(30px) 0;

    @include media-query(nav-portable) {
        margin: rem(30px) 0;
    }
}

.work-gallery {
    margin-bottom: rem(30px);

    @include media-query(wide) {
        width: percentage(2/3);
        max-width: rem(665px);
    }
}

.work-links {
    display: flex;
    flex-direction: row;

    // @include media-query(nav) {
    //     padding-top: rem(15px);
    // }
    @include media-query(nav-portable) {
        justify-content: space-between;
    }
}
    .work-prev,
    .work-next {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
            width: rem(33px); height: rem(12px);
            fill: #FFF;
            transition: transform $base-anim-speed $anim-ease-in-out;
        }

        span {

            @include media-query(nav-portable) {
                display: none;
            }
        }

        &.is-disabled {
            opacity: .24;
        }
    }
    .work-prev {

        span {
            padding-right: 12px;
        }

        &:not(.is-disabled):hover {
            svg {
                transform: translateX(rem(3px));
            }
        }
    }
    .work-next {
        margin-right: rem(30px);

        svg {
            transform: rotate(180deg);
        }
        span {
            padding-left: 12px;
        }

        &:not(.is-disabled):hover {
            svg {
                transform: translateX(rem(-3px)) rotate(180deg);
            }
        }
    }
