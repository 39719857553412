/*------------------------------------*\
    $FOOTER
\*------------------------------------*/

.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:0 -#{rem(30px)}; padding: rem(15px) rem(30px);
    width: calc(100% + #{rem(30px)} * 2);
    color: rgba(#FFF, .64);
    line-height: 1.4;
    font-size: 14px;
    border-top: 1px solid $colour-ui;

    @include media-query(nav-portable) {
        font-size: 12px;
    }
}
    .footer-item {

        &:not(:first-child) {
            flex: 1 1 auto;
            padding-left: rem(30px);
            text-align: right;
        }
    }

.footer-nav-list {
    margin: 0;
    display: flex;
    flex-direction: row;

    @include media-query(nav) {
        padding-left: rem(30px);
    }
    @include media-query(nav-portable) {
        order: -1;
        padding-bottom: rem(5px);
    }
}
    .footer-nav-item {

        &:not(:last-child) {
            padding-right: rem(15px);
        }
    }
