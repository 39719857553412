/*------------------------------------*\
    $QUOTE
\*------------------------------------*/

.quote {
    &:not(:last-child) {
        margin-bottom: rem(32px);
    }

    p {
        margin-bottom: rem(17px);
    }

    p:last-of-type {
        display: inline;
    }
}

.quote-source {
    color: $colour-secondary;
}
