/*------------------------------------*\
    $CATEGORIES
\*------------------------------------*/

.categories-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(-30px);

    @include media-query(nav-portable) {
        display: none;
    }
}

.categories-item {
    margin-left: rem(30px);
}

.categories-link {
    color: $colour-secondary;

    &:hover,
    &.is-active {
        color: $colour-primary;
    }
}

.categories-select {
    position: relative;
    margin-bottom: rem(30px);

    select {
        @include font-size(20px, 28px);
        appearance: none;
        background: none;
        border: none;
        border-bottom: rem(2px) solid $colour-secondary;
        border-radius: 0;
        width: 100%;
        padding: rem(8px) rem(44px) rem(8px) 0;
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: rem(14px);
        pointer-events: none;
        width: rem(14px);
        height: rem(14px);
        border-right: rem(2px) solid $colour-secondary;
        border-bottom: rem(2px) solid $colour-secondary;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    @include media-query(nav) {
        display: none;
    }
}
