/*------------------------------------*\
    $PAGE
\*------------------------------------*/

.page {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-top: rem(88px); padding:0 rem(30px);

    @include media-query(nav-portable) {
        margin-top: rem(84px);
    }
}

.page-main {
    flex: 1 1 auto;
}

.page-main-full {
    max-width: none;
}

.page-main-lrg {
    max-width: rem(1024px);
}

.about-figure {
    margin-left:-#{rem(30px)}; margin-right:-#{rem(30px)};
    width: calc(100% + #{rem(30px)} * 2);
}
