/*------------------------------------*\
    $SHARED
\*------------------------------------*/

/**
 * Setup bottom margins for vertical rhythm
 */

h1, h2, h3, h4, h5, h6,
ol, ul, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
    margin-bottom: $base-line-height;
    margin-bottom: var(--typography-margin, $base-line-height);
}
