/*------------------------------------*\
    $BLEED
\*------------------------------------*/

@include media-query(palm) {
    .bleed-palm {
        margin-left: rem(-30px);
        margin-right: rem(-30px);
    }
}

@include media-query(nav-portable) {
    .bleed-nav-portable {
        margin-left: rem(-30px);
        margin-right: rem(-30px);
    }
}
