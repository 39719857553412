/*------------------------------------*\
    $RATIO
\*------------------------------------*/

// Define mixins
@mixin ratio($antecedent: 1, $consequent: 1) {
    position: relative;
    display: block;

    &::before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: ($consequent / $antecedent) * 100%;
    }
}

@mixin ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/**
 * Base ratio object
 */

.ratio {
    @include ratio;
}

.ratio-3\:2 {
    @include ratio(3, 2);
}

.ratio-content {
    @include ratio-content;
}
