/*------------------------------------*\
    $NAV
\*------------------------------------*/

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 10;
    padding: rem(30px);
    background: #000;

    @include media-query(nav-portable) {

        nav {
            width: 24px; height: 24px;
        }
    }
}

.nav-logo {
    margin-right: rem(30px);
    line-height: 1;
    font-size: 28px;

    @include media-query(nav-portable) {
        font-size: 18px;
    }
}

@keyframes burger-before {
    0% { transform: translateY(-6px); }
    50% { transform: none; }
    100% { transform: rotate(-45deg); }
}
@keyframes burger-before-rev {
    100% { transform: translateY(-6px); }
    50% { transform: none; }
    0% { transform: rotate(-45deg); }
}

@keyframes burger-after {
    0% { transform: translateY(6px); }
    50% { transform: none; }
    100% { transform: rotate(45deg); }
}
@keyframes burger-after-rev {
    100% { transform: translateY(6px); }
    50% { transform: none; }
    0% { transform: rotate(45deg); }
}

.nav-toggle {
    position: relative;
    color: $colour-secondary;
    width: 24px; height: 24px;

    @include media-query(nav) {
        display: none;
    }
}

.nav-toggle-icon {
    top: 50%;
    right: 0;

    &,
    &::before,
    &::after {
        position: absolute;
        transition: background-color 0s halve($base-anim-speed);
        background-color: $colour-primary;
        width: rem(24px);
        height: rem(1px);
    }

    &::before,
    &::after {
        content: "";
        top: 0;
        left: 0;
    }

    &::before {
        transform: translateY(rem(-8px));
    }

    &::after {
        transform: translateY(rem(8px));
    }

    .is-active > & {
        background-color: transparent;

        &::before {
            animation: burger-before $base-anim-speed forwards;
        }

        &::after {
            animation: burger-after $base-anim-speed forwards;
        }
    }

    .is-exiting > & {
        &::before {
            animation: burger-before-rev $base-anim-speed forwards;
        }

        &::after {
            animation: burger-after-rev $base-anim-speed forwards;
        }
    }
}

.nav-list {

    @include media-query(nav) {
        display: flex;
        flex-direction: row;
        margin: 0;
    }
    @include media-query(nav-portable) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $colour-ui;
        position: absolute;
        top: 0; left: 0;
        width: calc(100vw - 84px); height: 100vh;
        padding: rem(30px);
        margin-bottom: 0;
        z-index: 2;
        overflow: scroll;
        visibility: hidden;
        opacity: 0;
        transform: translateX(-100%);
        transition: visibility $base-anim-speed, opacity $base-anim-speed, transform $base-anim-speed;

        .has-active-nav & {
            visibility: visible;
            opacity: 1;
            transform: translateX(0%);
        }
    }
}

.nav-item {

    @include media-query(nav) {
        &:not(:last-child) {
            margin-right: rem(30px);
        }
    }
    @include media-query(nav-portable) {
        margin: rem(10px) 0;
        font-size: 22px;
    }
}

.nav-link {
    color: $colour-secondary;
    transition: color $base-anim-speed;

    @include media-query(nav) {
        line-height: 1.2;
        font-size: 14px;
    }

    &:hover,
    &.is-current {
        color: $colour-primary;
    }
}
