/*!
 * Architecture here based on inuitcss, by @csswizardry
 * github.com/inuitcss | inuitcss.com
 */
//------------------------------------*\
//  $SETTINGS
//------------------------------------*/
// Global variables, config switches
@import
    "settings/base",
    "settings/breakpoints";


//------------------------------------*\
//  $TOOLS
//------------------------------------*/
// Default mixins and functions
@import
    "tools/functions",
    "tools/mixins",
    "tools/responsive";


//------------------------------------*\
//  $GENERIC
//------------------------------------*/
// Ground-zero styles (Normalize.css, resets, box-sizing)
@import
    "generic/font-face",
    "generic/resetalize",
    "generic/shared";


//------------------------------------*\
//  $BASE
//------------------------------------*/
// Unclassed HTML element selectors
@import
    "base/html",
    "base/a",
    "base/headings",
    "base/img";


//------------------------------------*\
//  $OBJECTS
//------------------------------------*/
// Cosmetic-free design patterns
@import
    "objects/container",
    "objects/grid",
    "objects/icon",
    "objects/link-bare",
    "objects/ratio";


//------------------------------------*\
//  $COMPONENTS
//------------------------------------*/
// Designed components, chunks of UI
@import
    "components/categories",
    "components/columns",
    "components/cookie-notice",
    "components/footer",
    "components/lazyload",
    "components/lightgallery",
    "components/nav",
    "components/page",
    "components/post",
    "components/quote",
    "components/slick",
    "components/typography",
    "components/work";


//------------------------------------*\
//  $UTILITIES
//------------------------------------*/
// Helpers and overrides
@import
    "utilities/accessibility",
    "utilities/align",
    "utilities/bleed",
    "utilities/clearfix",
    "utilities/headings",
    "utilities/hide",
    "utilities/widths";


// Please do not add any styles to the bottom of this file - add
// edits to the relevant partial or create a new partial
