/*------------------------------------*\
    $COLUMNS
\*------------------------------------*/

.columns {
    // margin-top: rem(32px);
    margin-bottom: rem(32px);
    color: $colour-secondary;

    @include media-query(lap) {
        column-count: 2;
        margin-bottom: rem(16px);
    }
}

.column-item {
    margin-bottom: rem(16px);

    a.link-bare.is-current {
        color:#FFF;
        cursor:default;
    }
}
