/*------------------------------------*\
    $POST
\*------------------------------------*/

.post {
    margin-bottom: rem(3px);
}

.post-thumb {
    margin-bottom: rem(10px);
}

.post-title {
    @include font-size(14px);
    color: $colour-secondary;
}
