/* ------------------------------------------------------------------------------------------------------------------------ */
/* COOKIE NOTIFICATION ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.cookie-notice {
	transition-duration:300ms;
	transition-timing-function:ease-in-out;
	position:fixed;
	left:24px; bottom:24px;
	padding:24px;
	line-height:1.4;
	color:#FFF;
	font-size:14px;
	text-align:center;
	background:$colour-ui;
	box-shadow:0 3px 12px 0 rgba(#000, .12);

	@media screen and (min-width:769px)
	{
		z-index:99999;
		width:100%; max-width:260px;
		text-align:center;
	}
	@media screen and (max-width:768px)
	{
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;
		z-index:99998;
		left:12px; right:12px; bottom:12px;
	}

	&.hide {
		opacity:0;
		visibility:hidden;
	}
}

	.cookie-notice_message > a {
		transition-duration:300ms;
		transition-timing-function:ease-in-out;
		color:#FFF;
		text-decoration:underline;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:#FFF;
				text-decoration:none;
			}
		}
		@media screen and (max-width:768px)
		{
			flex:1;
		}
	}

	.cookie-notice_close-anchor {
		transition-duration:300ms;
		transition-timing-function:ease-in-out;
		display:inline-block;
		margin:12px auto 0;
		color:#FFF;
		font-weight:500;
		text-decoration:none;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:#FFF;
			}
		}
		@media screen and (max-width:768px)
		{
			flex:1 0 auto;
			margin:18px -24px -24px; padding:16px 0;
			width:100%;
			text-align:center;
			border-top:1px solid rgba(#FFF, .12);
		}
	}

	.cookie-notice_close-icon {
		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:center;
		transition-duration:300ms;
		transition-timing-function:ease-in-out;
		position:absolute;
		top:6px; right:6px;
		z-index:1;
		padding:4px;
		width:16px; height:16px;
		opacity:.24;
		box-sizing:content-box;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				opacity:1;
			}
		}
		@media screen and (max-width:768px)
		{
			top:0; right:0;
			padding:12px;
			width:12px; height:12px;
		}
	}
