/*------------------------------------*\
    $HEADINGS
\*------------------------------------*/

// Predefine heading mixins which can be reused in trumps layer later

@mixin heading {
    font-family: $font-primary;
    font-weight: $font-weight-normal;
}

@mixin heading-1 {
    @include font-size(24px, 1.2);
}

@mixin heading-2 {
    @include font-size(21px, 1.2);
}

@mixin heading-3 {
    @include font-size(21px, 1.2);
}

@mixin heading-4 {
    @include font-size(21px, 1.2);
}

@mixin heading-5 {
    @include font-size(21px, 1.2);
}

@mixin heading-6 {
    @include font-size(21px, 1.2);
}


/**
 * Declare base heading sizes and styles
 */

h1, h2, h3, h4, h5, h6 {
    @include heading;
}

h1 {
    @include heading-1;
}

h2 {
    @include heading-2;
}

h3 {
    @include heading-3;
}

h4 {
    @include heading-4;
}

h5 {
    @include heading-5;
}

h6 {
    @include heading-6;
}
