/*------------------------------------*\
    $WIDTHS
\*------------------------------------*/

/**
 * Widths
 */

$breakpoint-widths: (
    '' : (
    ),
    'palm' : (
    ),
    'portable' : (
    ),
    'lap' : (
        '1/2': 1/2,
    ),
    'desk': (
        '1/3': 1/3,
    ),
    'wide': (
        '1/4': 1/4,
    ),
);

@each $breakpoint, $widths in $breakpoint-widths {
    @if ($breakpoint != '') {
        // Wrap a media query around it if we've included a breakpoint
        @include media-query($breakpoint) {
            @each $width, $fraction in $widths {
                [class~="#{$width}-#{$breakpoint}"] {
                    @include grid-width($fraction);
                }
            }
        }
    } @else {
        // If not, just spit out the classes
        @each $width, $fraction in $widths {
            [class~="#{$width}"] {
                @include grid-width($fraction);
            }
        }
    }
}
