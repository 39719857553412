/*------------------------------------*\
    $GRID
\*------------------------------------*/

// Define grid gutter sizes
$grid-gutter-width:	$base-spacing-unit !default;


// Define mixins
@mixin grid($gutter-width: $grid-gutter-width, $margin-bottom: 0, $mode: flex) {
    margin: 0 0 $margin-bottom (0 - $gutter-width);
    display: $mode;
    flex-wrap: wrap;
}

@mixin grid-width($fraction, $gutter-width: $grid-gutter-width) {
    @if ($fraction == auto) {
        flex: 0 1 auto;
        width: auto;
    } @else {
        @if ($gutter-width != 0px) {
            width: calc(#{percentage($fraction)} - #{$gutter-width});
        } @else {
            width: #{percentage($fraction)};
        }
    }
}

@mixin grid-item($gutter-width: $grid-gutter-width, $fraction: 1) {
    @include grid-width($fraction, $gutter-width);
    margin-left: $gutter-width;
}


/**
 * Base grid object
 */

.grid {
    @include grid;
}

    .grid-item {
        @include grid-item;
    }

