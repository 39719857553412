/*------------------------------------*\
    $LINK-BARE
\*------------------------------------*/

.link-bare {
    color: currentColor;
    transition: color $base-anim-speed;

    &:hover {
        color: $colour-primary;
    }
}
