/*------------------------------------*\
    $CONTAINER
\*------------------------------------*/

/**
 * Contain elements in an arbitrary width
 */

@include media-query(nav) {
    .container-xs,
    .container-sml,
    .container-med,
    .container-lrg {
        width: 100%;
    }

    .container-xs {
        max-width: rem(465px);
    }

    .container-sml {
        max-width: rem(600px);
    }

    .container-med {
        max-width: rem(700px);
    }

    .container-lrg {
        margin-bottom: rem(45px);
        max-width: rem(960px);

        @include media-query(nav-portable) {
            margin-bottom: rem(30px);
        }
    }
}
