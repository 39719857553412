/*------------------------------------*\
    $LAZYLOAD
\*------------------------------------*/

.lazyload-wrapper {
    position: relative;
    background-color: $colour-ui;
    margin-bottom: $base-spacing-unit;
}

.lazyload-figure {
    opacity: 0;
    transition: opacity $base-anim-speed;

    &.lazyloaded {
        opacity: 1;
    }
}


.lazyload-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    max-height: 75%;
    max-width: 75%;
    font-family: 'object-fit: contain;';

    &.lazyloaded {
        opacity: 0.2;
    }
}
