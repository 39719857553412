//------------------------------------*\
//  $SETTINGS-BASE
//------------------------------------*/

// High level base settings
$base-font-size:            16px;
$base-line-height:          24px;
$base-spacing-unit:         24px / $base-font-size * 1rem; // 24px
$base-text-colour:          #fff;
$base-background-colour:    #000;
$base-font:                 "NeuzeitSLTStd-Book", sans-serif;
$base-anim-speed:           300ms;


// Colour Palette
$colour-primary:            #fff;
$colour-secondary:          #808080;
$colour-ui:                 #1e1e1e;


// Typefaces
$font-primary:              $base-font;


// Weights
$font-weight-light:         300;
$font-weight-normal:        400;
$font-weight-medium:        500;
$font-weight-bold:          700;


// Animation
$anim-ease-in:              ease-in;
$anim-ease-out:             ease-out;
$anim-ease-in-out:          ease-in-out;
