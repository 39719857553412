/*------------------------------------*\
    $PAGE
\*------------------------------------*/

/**
 * Set font defaults, base colours and high-level page stylings
 */

html {
    font: #{($base-font-size/16px)*1em}/#{$base-line-height/$base-font-size} $base-font;
    color: $base-text-colour;
    background-color: $base-background-colour;
    overflow-y: scroll;
    min-height: 100%;
}

html,
body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}


/**
 * Set base branded user interactions
 */

::selection {
    background-color: $colour-primary;
    color: #fff;
    text-shadow: none;
}

@mixin focus {
    outline: 2px solid $colour-primary;
    outline-offset: 2px;

    body:not(.has-tabbing-user) & {
        outline: none;
    }
}

:focus {
    @include focus;
}

body:focus {
    outline: none;
}
