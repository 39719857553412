/*------------------------------------*\
    $SLICK
\*------------------------------------*/

.slick-container {
    position: relative;
    margin:0 -#{rem(30px)};
    width: calc(100% + #{rem(30px)} * 2);
    overflow: hidden;

    @include media-query(nav) {
        height: calc(100vh - #{rem(88px)});
    }
    @include media-query(nav-portable) {
        height: calc(100vh - #{rem(84px)});
    }
}

.slick-slider {
    margin-bottom: 0;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    @include media-query(nav) {
        height: calc(100vh - #{rem(88px)});
    }
    @include media-query(nav-portable) {
        height: calc(100vh - #{rem(138px)});
    }

    &:not(.slick-initialized) {
        @include clearfix;
    }
}

.slick-list {
    position: relative;

    display: block;

    margin: 0;
    padding: 0;

    @include media-query(nav) {
        height: calc(100vh - #{rem(88px)});
    }
    @include media-query(nav-portable) {
        height: calc(100vh - #{rem(138px)});
    }

    @include media-query(nav) {
        overflow: hidden;
    }
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-bottom: 0;

    @include media-query(nav) {
        height: calc(100vh - #{rem(88px)});
    }
    @include media-query(nav-portable) {
        height: calc(100vh - #{rem(138px)});
    }
}

.slick-track::before,
.slick-track::after {
    content: '';
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    outline: none;

    .slick-slider:not(.slick-initialized) & {
        float: none;

        &:not(:first-child) {
            display: none;
        }
    }

    .lazyload-wrapper {
        margin: 0;

        @include media-query(nav) {
            height: calc(100vh - #{rem(88px)});
        }
        @include media-query(nav-portable) {
            height: calc(100vh - #{rem(138px)});
        }

        img {
            object-fit: cover;
            font-family: 'object-fit: cover;';

            &.desktop-crop-top {
                object-position: top;
                font-family: 'object-fit: cover; object-position: top;';
            }
            &.desktop-crop-bottom {
                object-position: bottom;
                font-family: 'object-fit: cover; object-position: bottom;';
            }

            @include media-query(nav-portable) {
                &.mobile-crop-left {
                    object-position: left;
                    font-family: 'object-fit: cover; object-position: left;';
                }
                &.mobile-crop-right {
                    object-position: right;
                    font-family: 'object-fit: cover; object-position: right;';
                }
            }
        }
    }
}
[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}


/**
 * Custom Styles
 */

.slick-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-query(nav) {
        position: absolute;
        bottom: 0;
        right: rem(30px);
        margin-bottom: $base-spacing-unit;
        width: rem(160px);
    }
    @include media-query(nav-portable) {
        margin: rem(15px) rem(30px);
    }
}

.slick-counter {
    color: $colour-primary;
}

.slick-arrow {
    .icon {
        width: rem(33px);
        height: rem(12px);
        transition: transform $base-anim-speed $anim-ease-in-out;
    }
}

.slick-prev {
    .icon {
        transform: rotate(0.5turn);
    }

    &:hover {
        .icon {
            transform: translateX(rem(-3px)) rotate(0.5turn);
        }
    }
}

.slick-next {
    &:hover {
        .icon {
            transform: translateX(rem(3px));
        }
    }
}

.slick-caption {
    position: absolute;
    left: 0; bottom: 0;
    margin-left: rem(30px);
    opacity: 0;
    transition: opacity $base-anim-speed;

    .slick-active & {
        opacity: 1;
        transition-delay: $base-anim-speed;
    }
}

@keyframes slick-timer-rotate {
    0% {
        transform: rotate(0turn);
    }

    100% {
        transform: rotate(1turn);
    }
}

@keyframes slick-timer-appear {
    0%, 50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.slick-timer {
    position: absolute;
    top: rem(15px);
    right: rem(15px);
    width: 48px;
    transform: rotate(-0.25turn);
    height: 48px;
    pointer-events: none;
}

.slick-timer-progress {
    opacity: 0;
    stroke-dasharray: 326.726, 326.726;

    .is-animating > & {
        opacity: 1;
        animation: slick-timer-fill 5s linear forwards;
    }
}

@keyframes slick-timer-fill {
    0% {
        stroke-dashoffset: 326.726;
    }

    100% {
        stroke-dashoffset: 0;
    }
}
