/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

.typography {
    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    h3, h4, h5, h6 {
        @include font-size(14px);
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}


.typography-links-bare {
    a:not(:hover) {
        text-decoration:none;
    }
}
